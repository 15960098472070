import { BookingPayloadModel } from "./interface/booking/bookingInterface";
import apiRequest from "../../api/apiRequest";
import { startLoader, stopLoader } from "../../util/loader";
import { isTestEnvironment, isValidZipCode, storeFailedResponse, removeNullAndEmpty, normalizeKeys, enviromentCheck } from "./helperModules/helperFunctions";
import { timeBlockInfoKeys, regionalTaxKeys, taxRatesKeys, taxAuthoritiesKeys, hmsRoleDetailKeys, propertyDetailsKeys, packagePayloadKeys, servicePayloadKeys, bookingPayloadModelKeys, leadAdditionalNotesKeys, imageUrlsKeys, hmsServiceBundleKeys, hmsAvailableServiceKeys, jobPromiseEndTimeKeys, hmsRoleInfosKeys } from "./keys/bookingKeys";
import { handleFakeSubmit } from "../../util/share";
import { handleMissingData } from "../../util/discovery";
import { successLogger } from "../../api/apiTester";

//Check if the payload is valid
function isValidBookingPayload(payload: any): payload is BookingPayloadModel {
    // checking if all mandatory fields are present
    const mandatoryFields = ['webLocationId', 'postalCode', 'city', 'state', 'address', 'conceptCalledId', 'isLeadOnly', 'callType', 'campaignName', 'leadOrigin', 'firstName', 'lastName'];
    const hasAllMandatoryFields = mandatoryFields.every(field => payload.hasOwnProperty(field));
    if (!hasAllMandatoryFields) {
      console.log("Missing mandatory fields in the payload:", payload);
      return false;
    }

    //checking if any invalid keys are present
    if (!validateNestedObject(payload, bookingPayloadModelKeys)){
      console.log("Invalid keys or dataTypes detected in the payload:", payload);
      return false;
    }

    //check for valid Zipcode and updated trimmed zipcode
    if (!isValidZipCode(payload.zipCode)) return false;

    payload.zipCode = isValidZipCode(payload.zipCode);

    return payload;
}

//Prepopulating all the required field which come in via a web lead
async function prePopulatePayload(payload:any){
  payload.callType = payload.callType ?? 'In Bound';
  payload.campaignName = payload.campaignName ?? 'Web';
  payload.leadOrigin = payload.leadOrigin ?? 'BrandWebsite';
  payload.lastName = payload.lastName ?? ' ';
  //check for the enviroment and send in the payload
  payload.isTest = isTestEnvironment();
  const leadAdditionalNoteLabel = utmCampaign();
  if(leadAdditionalNoteLabel){
    payload.leadAdditionalNotes = payload.leadAdditionalNotes ?? {}
    if (payload.leadAdditionalNotes.leadAdditionalNoteLabel) {
        payload.leadAdditionalNotes.leadAdditionalNoteLabel += `, ${leadAdditionalNoteLabel}`;
    } else {
      payload.leadAdditionalNotes.leadAdditionalNoteLabel = leadAdditionalNoteLabel;
    }
  }
  const city =  payload.City ?? payload.city;
  const state = payload.State ?? payload.state;
  const out = await handleMissingData(city, state, payload.ZipCode ?? payload.zipCode);
  payload.city = out?.city || city;
  payload.state = out?.state || state;
  payload.address = payload.address.replace(/^,/, '').trim();
  

  //Tech Story 480733: [OPUS 2.0] Remove the param "Comments" & "Preferred Method" from booking end point in OPUS 2.0
  delete payload.comments;
  delete payload.preferredCommunicationType;
  //- 
  return payload;
}

function utmCampaign(){
  const url = new URL(window.location.href);
  const utmParams = ['campaign', 'source', 'medium', 'term', 'content', 'device'].reduce((obj, key) => {
    const value = url.searchParams.get(`utm_${key}`);
    if (value) {
      obj[`Marketing${key.charAt(0).toUpperCase() + key.slice(1)}`] = value;
    }
    return obj;
  }, {} as { [key: string]: string });
  const leadAdditionalNoteLabel = Object.entries(utmParams).map(([key, value]) => `${key}: ${value}`).join(', ');
  return leadAdditionalNoteLabel;
}

//Key Validator starts
function validateObjectKeys(obj: any, validKeys: { [key: string]: string }): boolean {
  return Object.keys(obj).every(key => {
    if (!validKeys.hasOwnProperty(key)) {
        return false
    };
    const expectedType = validKeys[key];
    const actualType = Array.isArray(obj[key]) ? 'object' : typeof obj[key];
    if(actualType !== expectedType)
      console.log(`The ${key} has ${actualType} but the expected type is ${expectedType}`)
    return actualType === expectedType;
  });
}

function validateNestedObject(obj: any, keyMap: { [key: string]: string }): boolean {
  if (!validateObjectKeys(obj, keyMap)) {
    console.log("Main Root Level payload validation failed")
    return false
  };

  if (obj.hmsRoleInfo && !validateHmsRoleInfo(obj.hmsRoleInfo)) {
    console.log("HMSInfo payload validation failed")
    return false
  };
  if (obj.timeBlock && !validateObjectKeys(obj.timeBlock, timeBlockInfoKeys)) {
    console.log("TimeBlock payload validation failed")
    return false
  };
  if (obj.hmsRoleInfo?.hmsSelectedServiceDetail?.hmsServiceBundles && !validateServiceBundles(obj.hmsRoleInfo.hmsSelectedServiceDetail.hmsServiceBundles)) {
    console.log("HmsServiceBundles payload validation failed")
    return false
  };
  if (obj.leadAdditionalNotes && !validateAdditionalNotes(obj.leadAdditionalNotes)){
    console.log("LeadAdditionalNotes payload validation failed")
    return false
  };
  if (obj.imageUrls && !validateImageUrls(obj.imageUrls)){
    console.log("ImageUrls payload validation failed")
    return false
  };
  if (obj.jobPromiseEndTime && !validateObjectKeys(obj.jobPromiseEndTime, jobPromiseEndTimeKeys)){
    console.log("JobPromiseEndTime payload validation failed")
    return false
  };

  return true;
}

function validateHmsRoleInfo(hmsRoleInfo: any): boolean {
  if (!validateObjectKeys(hmsRoleInfo, hmsRoleInfosKeys)){
    console.log("hmsRoleInfosKeys payload validation failed")
    return false
  };
  if (hmsRoleInfo.hmsRoleDetail && !validateObjectKeys(hmsRoleInfo.hmsRoleDetail, hmsRoleDetailKeys)){
    console.log("hmsRoleDetail payload validation failed")
    return false
  };
  if (hmsRoleInfo.hmsPropertyDetail && !validateObjectKeys(hmsRoleInfo.hmsPropertyDetail, propertyDetailsKeys)) {
    console.log("hmsPropertyDetail payload validation failed")
    return false
  };
  if (hmsRoleInfo.hmsSelectedServiceDetail && !validateSelectedServiceDetail(hmsRoleInfo.hmsSelectedServiceDetail)) {
    console.log("hmsSelectedServiceDetail payload validation failed")
    return false
  };
  if (hmsRoleInfo.hmsTaxDetail && !validateTaxDetail(hmsRoleInfo.hmsTaxDetail)) {
    console.log("hmsTaxDetail payload validation failed")
    return false
  };

  return true;
}

function validateSelectedServiceDetail(selectedServiceDetail: any): boolean {
  if (!validateObjectKeys(selectedServiceDetail, servicePayloadKeys)) {
    console.log("servicePayloadKeys payload validation failed")
    return false
  };
  if (selectedServiceDetail.hmsAvailableServices && !validateAvailableServices(selectedServiceDetail.hmsAvailableServices)) {
    console.log("hmsAvailableServices payload validation failed")
    return false
  };
  if (selectedServiceDetail.hmsServiceBundles && !validateServiceBundles(selectedServiceDetail.hmsServiceBundles)) {
    console.log("hmsServiceBundles payload validation failed")
    return false
  };

  return true;
}

function validateAvailableServices(availableServices: any[]): boolean {
  for (const service of availableServices) {
    if (!validateObjectKeys(service, hmsAvailableServiceKeys)) {
      console.log("hmsAvailableServiceKeys payload validation failed")
      return false
    };
  }
  return true;
}

function validateServiceBundles(serviceBundles: any[]): boolean {
  for (const bundle of serviceBundles) {
    if (!validateObjectKeys(bundle, hmsServiceBundleKeys)) {
      console.log("hmsServiceBundleKeys payload validation failed")
      return false
    };
    if (bundle.packagePayload && !validatePackagePayload(bundle.packagePayload)) {
      console.log("serviceBundles payload validation failed")
      return false
    };  
  }
  return true;
}

function validatePackagePayload(packagePayload: any[]): boolean {
  for (const payload of packagePayload) {
    if (!validateObjectKeys(payload, packagePayloadKeys)) {
      console.log("packagePayload payload validation failed")
      return false
    };  
  }
  return true;
}

function validateTaxDetail(taxDetail: any): boolean {
  if (!validateObjectKeys(taxDetail, bookingPayloadModelKeys)) {
    console.log("taxDetail payload validation failed")
    return false
  };
  if (taxDetail.regionalTaxes && !validateRegionalTaxes(taxDetail.regionalTaxes)) {
    console.log("regionalTaxes payload validation failed")
    return false
  }; 

  return true;
}

function validateRegionalTaxes(regionalTaxes: any[]): boolean {
  for (const tax of regionalTaxes) {
    if (!validateObjectKeys(tax, regionalTaxKeys)) {
      console.log("tax payload validation failed")
      return false
    }; 
    if (tax.taxRate && !validateObjectKeys(tax.taxRate, taxRatesKeys)) {
      console.log("taxRate payload validation failed")
      return false
    }; 
    if (tax.taxAuthority && !validateObjectKeys(tax.taxAuthority, taxAuthoritiesKeys)) {
      console.log("taxAuthority payload validation failed")
      return false
    };
  }
  return true;
}

function validateAdditionalNotes(additionalNote: any[]): boolean {
  if (typeof additionalNote !== 'object') {
    console.log("Invalid additionalNote: not an object");
    return false;
  }

  if (!validateObjectKeys(additionalNote, leadAdditionalNotesKeys)) {
    console.log("leadAdditionalNotesKeys payload validation failed");
    return false;
  }
  return true;
}

function validateImageUrls(imageUrls: any[]): boolean {
  for (const url of imageUrls) {
    if (!validateObjectKeys(url, imageUrlsKeys)) {
      console.log("imageUrlsKeys payload validation failed")
      return false
    };
  }
  return true;
}

//Key Validator ends

//Calling the booking end point
export async function bookingUsPostCallWrapper(url: string, payload: any) {


  //returns true if pass and false if fail
  if(!enviromentCheck(url)){
    stopLoader();
    return false;
  }

  let formattedPayload = await prePopulatePayload(payload);
  
  const enableStrictValidation = process.env.ENABLE_STRICT_PAYLOAD_VALIDATION?.toLowerCase() === 'true';
  if(enableStrictValidation){
    formattedPayload = JSON.parse(JSON.stringify(normalizeKeys(formattedPayload)));
    formattedPayload = JSON.parse(JSON.stringify(removeNullAndEmpty(formattedPayload)));
    formattedPayload = await prePopulatePayload(formattedPayload);

    if (!isValidBookingPayload(formattedPayload)) {
      console.error('Invalid payload for Booking Endpoint:', formattedPayload);
      throw new Error('Invalid payload for Booking Endpoint:' + JSON.stringify(formattedPayload));
    }
    else{
      formattedPayload = isValidBookingPayload(formattedPayload);
    }
  }
  else{
    console.log("Strict Payload validation disabled") 
  }
  

  const request = {
    method: 'POST',
    url,
    data: formattedPayload,
  };
    //Code to handle Fake Submit
    if (request?.data?.firstName?.toLowerCase().includes("isfakesubmit")) {
      handleFakeSubmit(request);
      const formdata = JSON.stringify(payload);
      sessionStorage.setItem('fakeSubmitData',formdata);
      return true;
  }
  try {
    startLoader();
    const response = await apiRequest(request);
    successLogger(request.data, request.url, false);
    return response;
  } catch (err) {
    console.error('Error:', err);
    storeFailedResponse(request, err);
    
    throw(err);
  } finally {
    stopLoader();
  }
}

